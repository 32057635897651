import React from 'react';

const IntroBlock = ({ text, mail }) => {
  return (
    <div className="intro-block">
      <div className="inner-container">

        <div className="text">
          <div dangerouslySetInnerHTML={{__html : text }}></div>
          { mail &&
            <a className='button primary' href={ `mailto:${mail}` } target='_blank' rel='noopener noreferrer'>Contactez-moi<span>&#8594;</span></a>
          }
        </div>

      </div>
    </div>
  )
}
 export default IntroBlock ;
