import React from 'react';
import posed from 'react-pose';

const Wrapper = posed.div({
  initial: {opacity: 0, x: 40},
  mount: {opacity: 1, x: 0, delayChildren: 200, transition: {duration: 500}} 
});

const PageHeader = ({ pageTitle }) => (
  
  <div className="page-header">
      
    <Wrapper className="content" initialPose="initial" pose="mount">
      <div className="inner-container">
        <h1 dangerouslySetInnerHTML={{__html : pageTitle }} />
      </div>
    </Wrapper>

  </div>

)

export default PageHeader ;
