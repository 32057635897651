import React from 'react';
import Img from 'gatsby-image' ;

const AdressBlock = ({ adress, telephone, email, img, alt }) => {
  return (
    <div className="adress-block">
      <div className="inner-container" >
        <div className="text">
          <div className="field">
            <span className="field-header">Adresse</span>
            <div className="field-content" dangerouslySetInnerHTML={{ __html : adress }} />
          </div>
          <div className="field">
            <span className="field-header">Téléphone</span>
            <div className="field-content" dangerouslySetInnerHTML={{ __html : telephone }} />
          </div>
          <div className="field">
            <span className="field-header">email</span>
            <div className="field-content" dangerouslySetInnerHTML={{ __html : email }} />
          </div>
        </div>
        <div className="image">
          <Img fluid={img} 
            alt={alt} 
            title={alt}/>
        </div>
      </div>
    </div>
  )
}

export default AdressBlock ;
