import React from "react";
import {graphql} from "gatsby";
import Helmet from "react-helmet";

import Layout from "../components/layout";
import PageHeader from "../components/page-header.js";
import IntroBlock from "../components/intro-block.js" ;
import AdressBlock from "../components/adress-block.js" ;
import NewsletterBlock from "../components/newsletter-block.js" ;

const Contact = ({ data }) => {

  const page = data.page ;
  const newsletter = data.newsletter ;
  const mail = data.mail.acf.mail_url ;

  return (
    <Layout>
      <Helmet title={page.title}></Helmet>
      <PageHeader pageTitle={page.title} />
      <IntroBlock 
        text={page.acf.introduction} 
        mail={mail}
      />
      <AdressBlock
        adress={page.acf.adresse}
        telephone={page.acf.telephone}
        email={page.acf.email}
        img={page.acf.image_contact.localFile.childImageSharp.fluid}
        alt={page.acf.image_contact.alt_text}
      />
      <NewsletterBlock 
        BannerText={newsletter.acf.newsletter_text}
        backgroundImage={newsletter.acf.newsletter_img.localFile.childImageSharp.fluid}
        alt={newsletter.acf.newsletter_img.alt_text} 
      />
    </Layout>
  )
}

export const query  = graphql`
  query {
    page: wordpressPage (slug: {eq: "contact"}){
      title
      acf {
        introduction
        image_contact {
          alt_text
          localFile {
            name
            childImageSharp {
              fluid (
                maxWidth: 800, 
                maxHeight: 600, 
                cropFocus: CENTER
              ){
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        adresse
        telephone
        email
      }
    }
    newsletter: wordpressPage (slug: {eq: "home"} ) {
      acf {
        newsletter_text
        newsletter_img {
          alt_text
          localFile {
            name
            childImageSharp {
              fluid (maxWidth: 1200){
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    mail: wordpressPage (slug: {eq: "home"} ) {
      acf {
        mail_url
      }
    }
  }
`
 export default Contact ;
